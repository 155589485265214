<template>

      <v-container>
        <v-row>        
          <v-col class="">
            <h2 class="mb-5">
              보유자산 조회
            </h2>
            <!-- <router-link to="/foo">foo</router-link> -->
            <p>보유 자산을 조회하기 위해서는 <br> 카카오 간편인증이 필요합니다.</p>
          </v-col>

        </v-row>
        
        <v-row>
          <v-col>
            <v-text-field
                v-model="name"
                label="성명"
                required
              ></v-text-field>
          </v-col>      
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                v-model="phone"
                type="number"
                label="전화번호"
                required
              ></v-text-field>
          </v-col>      
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                v-model="juminFirst"
                type="number"
                label="주민번호 앞자리"
                v-mask="'#######'"
                required
              ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                v-model="juminLast"
                label="주민번호 뒷자리"
                v-mask="'#######'"
                type="password"
                required
              ></v-text-field>
          </v-col>
        </v-row>

        <div class="bottomFixedDiv pr-5 pl-5">
          <v-btn 
            large
            block
            color="primary"
            elevation="0"
            class="bottomButton"
            @click="certRequest()"
          >
              보유자산 조회하기
          </v-btn>
        </div>
        <!-- <v-bottom-navigation
          absolute
          horizontal
          elevation="0"
        >
          <v-row class="ma-0 pa-5 mb-5">
          <v-col class="ma-0 pa-0">
            <v-btn elevation="0" block @click="certRequest()" class="bottomButton">카카오로 인증하기</v-btn>
          </v-col>
        </v-row>
        </v-bottom-navigation>         -->
        
      </v-container>
</template>

<script>

  import axios from 'axios';
  export default {
    data: () => ({
      name : null,
      phone : null,
      regNo : null,
      juminFirst : null,
      juminLast : null,
      certResponseData : {},
    }),
    mounted() {
        // let config = {
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem('token'),
        //   }
        // };
        // const vm = this;
        // axios.get("http://localhost:8000/users/me", config).then(function(resp){
        //   // console.log(resp);
        // }).catch(function(err){
        //   // 인증이 만료된 경우 -> 이경우 router에서 처리하도록 변경 필요
        //   if (err.response.status==401) {
        //     vm.$router.replace("/login");
        //   }
        // });
        
    },
    methods : {
      test : function() {
        console.log(this.$store.state);
      },
      certRequest : async function() {        
        const payload = {
          "name": this.name,
          "phone": this.phone,
          "ssn1": this.juminFirst,
          "ssn2": this.juminLast
        }

        if (this.juminFirst != null) {
          if (["1", "2", "5", "6"].includes(this.juminLast.slice(0,1)) == true) {
            // console.log(this.juminLast.slice(0,1));  
            payload.birthday = "19" + this.juminFirst
          } else {
            payload.birthday = "20" + this.juminFirst
          }
        }
              
        this.$store.state.userInfo = payload;
        
        let config = {
          headers: {
            // Authorization: "Bearer " + this.$store.state.token,
            Authorization: "Bearer " + localStorage.getItem('token'),
          }
        }
        console.log(config);
        axios.post(process.env.VUE_APP_URL+ "/cert/request", payload, config).then((resp) => {
          if (resp.data.clientMessage == "성공") {
            // this.certResponseData = resp.data;
            this.$store.state.certResponseData = resp.data;
            // 앱이 설치되어 있는 경우 카카오톡이 열림
            
            var app_scheme = 'kakaotalk://me/cert/sign?tx_id='+resp.data.cxId;
            window.location.href = app_scheme;

            this.$router.push({path:'/cert/confirm'});
          } else {
            console.log(resp.data);
            this.$root.vtoast.alert(resp.data.systemMessage)  
          }
        }).catch((error) => {
          this.$root.vtoast.alert("알수 없는 에러가 발생하였습니다.")
          throw new Error(error);
        });
        
      },
      
    },
    watch: {
      juminFirst: function (val) {
        this.regNo = val + this.juminLast;
        
      },
      juminLast: function (val) {
        this.regNo = this.juminFirst + val;
        
      }
    }
  }
</script>

<style>

</style>