<template>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      class="mb-5"
      large
      >
      {{ snackbar_msg }}      
    </v-snackbar>

</template>

<script>
export default {
    name: "vToast",
    data() {
    return{
        snackbar : false,
        snackbar_msg : "",
    }
    },
    methods:{
    alert(msg) {
        this.snackbar = true;
        this.snackbar_msg = msg;
    },
    }
}
</script>

<style>

</style>