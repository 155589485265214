<template>

  <v-container>
    <v-row>
      <v-col class="">
        <h2 class="mb-5">
          카카오 로그인
        </h2>
        <p>보유 자산을 조회하기 위해서는<br>카카오 로그인이 필요합니다.</p>
      </v-col>
    </v-row>
    
        <!-- <v-btn dark large elevation="0" block v-on:click="kakaoLogin" class="kakaoLoginButton">카카오로 로그인하기</v-btn> -->
        <div class="bottomFixedDiv pr-5 pl-5">
          <v-btn 
            large
            block
            elevation="0"
            class="kakaoLoginButton"
            @click="kakaoLogin"
          >
              카카오로 로그인
          </v-btn>
        </div>    
    
  </v-container>
</template>

<script>
  
    export default {

        name: 'LoginVue',
        data: () => ({
            client_id: "69ddbe96e14435de2e4d34bb0c7e97a3",
            redirect_uri: process.env.VUE_APP_LOGIN_REDIRECT_URL
        }),
        methods : {
            kakaoLogin() {

                window.location.href = this.kakaoLoginLink;
            },
            getKakaoAccount() {
                window.Kakao.API.request({
                    url:'/v2/user/me',
                    success : res => {
                        const kakao_account = res.kakao_account;
                        console.log(kakao_account);
                    },
                    fail : error => {
                        console.log(error);
                    }
                })
            }
        },
        watch: {
        },
        computed : {
            kakaoLoginLink() {
                return `https://kauth.kakao.com/oauth/authorize?client_id=${this.client_id}&redirect_uri=${this.redirect_uri}&response_type=code`;
            },

        }
    }
</script>

<style>


</style>