import Vue from 'vue'
import VueRouter from 'vue-router'
// import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Login from '../views/Login.vue'
import Token from '../views/Token.vue'
import CertRequest from '../views/CertRequest.vue'
import CertConfirm from '../views/CertConfirm.vue'
import Loading from '../views/Loading.vue'
import Error from '../views/Error.vue'
import Result from '../views/Result.vue'
import Sample from '../views/Sample.vue'

import Salary from '../views/Salary.vue'
import SalaryDetail from '../views/SalaryDetail.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/cert/request',
    name: 'CertRequest',
    component: CertRequest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login/token/:token',
    name: 'Token',
    component: Token
  },
  {
    path: '/cert/confirm',
    name: 'CertConfirm',
    component: CertConfirm,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/error',
    name: 'Error',
    component: Error
  },
  {
    path: '/',
    name: 'Result',
    component: Result
  },
  {
    path: '/sample',
    name: 'Sample',
    component: Sample
  },
  {
    path: '/salary',
    name: 'Salary',
    component: Salary,
    
  },
  {
    path: '/salary/:id',
    name: 'SalaryDetail',
    component: SalaryDetail,
    
  },
]

const router = new VueRouter({
  // mode: 'history',
  // hash: tre,
  // history: createWebHashHistory(),
  // base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // },
})

import axios from 'axios';

router.beforeEach((to, from, next) => {



  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem('token'),
      }
    };
    // axios.defaults.withCredentials = true;
    axios.get(process.env.VUE_APP_URL+"/users/me", config).then(function(resp){
    // axios({
    //   method: 'get',
    //   baseURL: process.env.VUE_APP_URL,
    //   url:'/users/me',
    //   config
    // }).then(function (resp){
      console.log(resp);
    }).catch(function(err){
      console.log(err);
      if (err.response.status==401) {
        next('/login')
      }
    });
    next()
  } else {
    next()
  }
})



export default router
