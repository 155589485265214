<template>

      <v-container>
        <v-row>
        
          <v-col class="mt-5">
            <p>{{$route.params.token}}</p>
          </v-col>

        </v-row>

      </v-container>
</template>

<script>

    // import axios from 'axios';
    export default {

        name: 'TokenVue',
        mounted: function () {
            this.$nextTick(function () {            
                // this.calcResult = this.$store.state.calcResult;
                // this.assets = this.$store.state.assets;
                console.log(this.$route.params.token);
                // this.$store.state.token = this.$route.parmas.token;
                this.$store.commit('setToken', this.$route.params.token);
                localStorage.setItem("token", this.$route.params.token);
                this.$router.replace({path:'/'});
            })
        },
        data: () => ({
            
        }),
        methods : {

        },
        watch: {
            
        },
        computed : {

        },
    }
</script>
