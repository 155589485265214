import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token : ""
  },
  getters: {
  },
  mutations: {
    setToken (state, token) {
      // mutate state
      // console.log(state);
      // console.log(token);
      state.token = token;
    }
  },
  actions: {
  },
  modules: {
  }
})
