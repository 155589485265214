<template>


        <v-container>
            
            <!-- <h1>자산</h1>
            <v-spacer class="ma-10"></v-spacer> -->
            <!-- <p class="text-subtitle-1 mb-0">예상 총가치</p> -->
            <!-- <div class="fixed-bar" v-show="show">
                <div class="mb-10 ml-3">
                    <v-row align="center">
                        <svg class='mr-3' width="75" height="30" viewBox="0 0 75 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" fill="#0D9AFF"><path d="m15.536 8.19.829-7.929c7.455.775 13.268 7.076 13.268 14.736 0 8.184-6.634 14.818-14.818 14.818S-.003 23.181-.003 14.997C-.003 6.814 6.631.18 14.815.18v7.973a6.845 6.845 0 1 0 .72.039Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M71.414 2H68.43v2.97H55.99V2h-2.984v10.314h18.408V2ZM68.43 9.941H55.99V7.339h12.44v2.603Z"></path><path d="M43.295 2.68h-5.016l-4.186 4.4 2.08 1.77 3.7-3.826h.244v19.137h-5.595v2.684h14.303V24.16h-5.53V2.68ZM72.511 24.743v2.43l.004.003H54.79c-.648 0-1.133-.161-1.466-.481-.331-.328-.493-.798-.493-1.405v-5.688h3.04v5.141h16.639Z"></path><path d="M60.998 21.998h3.077v-5.025H75v-2.485H49.257v2.485h11.74v5.025Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h75v30H0z"></path></clipPath></defs></svg>                    
                    </v-row>
                </div>
            </div> -->
            
            
            <div class="top-navi">
                <div class="logo">
                    <svg width="75" height="30" viewBox="0 0 75 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" fill="#0D9AFF"><path d="m15.536 8.19.829-7.929c7.455.775 13.268 7.076 13.268 14.736 0 8.184-6.634 14.818-14.818 14.818S-.003 23.181-.003 14.997C-.003 6.814 6.631.18 14.815.18v7.973a6.845 6.845 0 1 0 .72.039Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M71.414 2H68.43v2.97H55.99V2h-2.984v10.314h18.408V2ZM68.43 9.941H55.99V7.339h12.44v2.603Z"></path><path d="M43.295 2.68h-5.016l-4.186 4.4 2.08 1.77 3.7-3.826h.244v19.137h-5.595v2.684h14.303V24.16h-5.53V2.68ZM72.511 24.743v2.43l.004.003H54.79c-.648 0-1.133-.161-1.466-.481-.331-.328-.493-.798-.493-1.405v-5.688h3.04v5.141h16.639Z"></path><path d="M60.998 21.998h3.077v-5.025H75v-2.485H49.257v2.485h11.74v5.025Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h75v30H0z"></path></clipPath></defs></svg>                
                </div>
                <div class="icon">
                    <a href="#" onclick="ChannelIO('showMessenger');" style="font-size:16px;color: rgb(13, 154, 255);">AI세무상담</a>
                </div>
            </div>

            <h2 class="mt-10 pt-5 mb-3">자산가치</h2>
            <p class="mb-1"><span class="total_amt">{{totalAmt.toLocaleString()}}원</span></p>
            <p class="mb-10"><small>공시지가를 기준으로 현재가치를 계산하였습니다.</small></p>
        
            <!-- <v-alert text="..."></v-alert> -->
            
            <!-- <v-spacer class="ma-0"></v-spacer> -->
            <!-- <p class="text-subtitle-2 mb-0">예상 세금</p>
            <h3 class="mb-5">{{totalTaxAmt.toLocaleString()}}원</h3>
            -->

            <div class="spacer mb-5"></div>
            <h2 class="mt-10 mb-1">자산 목록</h2>
            <p><small>각 자산을 눌러 양도세를 다시 계산 가능합니다.</small></p>

            <template
            v-for="(asset, index) in assets" 
            >

                <v-card
                class="mb-5 card"
                outlined
                @click="assetDetail(index)"    
                v-bind:key="asset.iros_id"    
                >
                    <template v-if="asset.구분=='토지'">
                        <v-img
                        height="150"
                        :src="require('../assets/CK_cb2110002994_l.jpg')"
                        ></v-img>
                        <v-card-title class="text-subtitle-1">{{asset.주소}}</v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col>
                                    <v-list-item two-line class="pa-2">
                                        <v-list-item-content>
                                            <v-list-item-title>예상 시세</v-list-item-title>
                                            <v-list-item-subtitle><b>{{numeral(asset.추정시가).format("0,0")}}</b>원</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col>
                                    <v-list-item two-line class="pa-2">
                                        <v-list-item-content>
                                            <v-list-item-title>예상 양도세</v-list-item-title>
                                            <v-list-item-subtitle><b>{{numeral(asset.양도세+asset.양도세지방세).format("0,0")}}</b>원</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </template>
                    <template v-if="asset.구분=='주택'">
                        <v-img
                        :src="require('../assets/CK_ti367a25107_n_l.jpg')"
                        height="150"
                        />
                        <!-- <v-img
                        height="100"
                        src="https://pixabay.com/get/g54637cff15404a1164069c96b6d48e2bcb35e66c43c6b0649c69fdc915a462108d62039e4cd415bd6487ba9af497f28d_640.jpg"
                        ></v-img> -->
                        <v-card-title class="text-subtitle-1">{{asset.주소}}</v-card-title>
                        <v-card-text>
                            
                            <v-row>
                                <v-col>
                                    <v-list-item two-line class="pa-2">
                                        <v-list-item-content>
                                            <v-list-item-title>예상 시세</v-list-item-title>
                                            <v-list-item-subtitle><b>{{numeral(asset.추정시가).format("0,0")}}</b>원</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col>
                                    <v-list-item two-line class="pa-2">
                                        <v-list-item-content>
                                            <v-list-item-title>예상 양도세</v-list-item-title>
                                            <v-list-item-subtitle><b>{{numeral(asset.양도세+asset.양도세지방세).format("0,0")}}</b>원</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </template>
                </v-card>
            </template>

            
            <div class="ma-15"></div>
            <v-row class="ma-0 pa-0 ">
                <v-col 
                    cols="12"
                    class="ma-0 pa-0">
                    <v-btn 
                        color="primary"
                        large
                        elevation="0"
                        block
                        class="bottomButton"
                        @click="$router.push('/cert/request')"
                    >
                        보유자산 조회하기
                    </v-btn>
                </v-col>
            </v-row>
            <div class="ma-15"></div>
            <v-dialog
            v-model="dialog"
            width="500px"
            >
            <v-card class="pa-3">
                <v-card-text class="mt-5 mb-0 pb-0">
                    <p>주택수를 선택해주세요.</p>
                    <v-row class="mb-5">
                        <v-col cols="12">
                            <v-btn-toggle v-model="주택수" block>
                                <v-btn>무주택</v-btn>
                                <v-btn>1주택</v-btn>
                                <v-btn>2주택</v-btn>
                                <v-btn>3주택이상</v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>

                    <p>매각가액을 입력해주세요.</p>
                    <v-row>
                        <v-col>                            
                            <v-text-field
                            dense
                            v-model="target_asset.추정시가"
                            label="매각가격"
                            outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <p>필요경비를 입력해주세요.</p>
                    <v-row>
                        <v-col>                            
                            <v-text-field
                            dense
                            v-model="target_asset.취득세"
                            label="필요경비"
                            outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn 
                    color="primary"
                    large
                    elevation="0"
                    block
                    :loading="loading"
                    @click="reCalc()">
                    저장하기</v-btn>
                    
                </v-card-actions>
            </v-card>
            </v-dialog>

            <v-overlay v-model="page_loading">
                <v-progress-circular
                    :size="80"
                    :width="10"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-overlay>
        </v-container>
</template>

<script>

import axios from 'axios';
import numeral from 'numeral';

export default {
    name: 'CalcResult',

    components: {
        // CertRequest,
        // numeral,
    },
    data: () => ({
        show: false, // 바가 보이도록 제어하는 데이터
        assets : {},
        dialog : false,
        loading: false,
        page_loading : false,
        target_asset : {},
        selected_asset : null,
        주택수 : 0,
        config : {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        }
    }),

    methods : {
        numeral,
        handleScroll() {
        // 스크롤 위치가 일정 이상 내려가면 바를 보이도록 함
        this.show = window.pageYOffset > 100;
        },
        assetDetail(index) {
            this.dialog = true;
            this.selected_asset = index;
            this.target_asset = JSON.parse(JSON.stringify(this.assets[index]));
            
            console.log(this.target_asset);
        },
        getAssets() {
            
            // let vm = this;
            axios.get(process.env.VUE_APP_URL+ "/query/asset", this.config).then((resp) => {
                console.log(resp);
                if (resp.data.error == false) {
                    this.assets = resp.data.assets;
                    
                    // assets 중에 "주택" 개수 계산
                    let count = 0;
                    for (let i = 0; i < this.assets.length; i++) {
                        if (this.assets[i].자산구분 == "주택") {
                            count++;
                        }
                    }
                    this.주택수 = count;
                    this.page_loading = false;
                } else {
                    this.$router.push({path:'/login'});
                }
            }).catch((err) => {
                console.log(err);
                this.$router.push({path:'/login'});
            })
            // 조회된 데이터가 있는 경우 (s3 파일이 있는 경우) -> 전역변수에서 데이터 가져오도록 되어 있는데, 백엔드에서 가져오도록 변경 필요
            // this.assets = this.$store.state.assets;

            // 조회결과가 없는 경우 (s3 파일이 없는 경우) -> 간편인증 페이지로 이동

        },
        reCalc() {
            console.log(this.주택수);
            this.loading = true;
            // this.target_asset.추정시가 = parseInt(this.target_asset.추정시가);
            this.target_asset.주택수 = this.주택수;
            this.target_asset.추정시가 = numeral(this.target_asset.추정시가).value();
            this.target_asset.취득세 = numeral(this.target_asset.취득세).value();
            axios.post(process.env.VUE_APP_URL+ "/query/recalc", this.target_asset,  this.config).then((resp) => {
                console.log(resp);
                if (resp.data.error == false) {
                    this.target_asset.양도세 = resp.data.data;
                    this.target_asset.양도세지방세 = parseInt(this.target_asset.양도세 * 0.1);
                    this.assets[this.selected_asset] = this.target_asset;
                    this.loading = false;
                    this.dialog = false;
                    
                } else {
                    this.$router.push({path:'/login'});
                }
            });
        }
    },
    mounted: function () {
        // this.getAssets();

        this.page_loading = true;

        this.$nextTick(() => {

            this.getAssets();
            

        })
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    computed : {
        totalAmt : function() {
            var sum = 0;
            for (var index in this.assets) {
                sum += numeral(this.assets[index].추정시가).value();
            }
            return sum
        },
        totalTaxAmt : function() {
            var sum = 0;
            for (var index in this.assets) {
                sum += parseInt(this.assets[index].양도세) + parseInt(this.assets[index].양도세지방세);
            }
            return sum
        },
        // taxSum : function() {
        //     var sum = 0;
        //     for (var key in this.calcResult) {
        //         sum += parseInt(this.calcResult[key].환급세액);
        //     }
        //     return sum
        // }     
        // sellPrice : function() {

        // }   
    },
    watch : {
        'target_asset.추정시가' : function(price) {
            this.target_asset.추정시가 = numeral(price).format("0,0");            
        },
        'target_asset.취득세' : function(price) {
            this.target_asset.취득세 = numeral(price).format("0,0");            
        },
    },

};
</script>

<style scoped>
.total_amt {
    font-size: 25px !important;
    font-weight: bold;    
    line-height: 1.3;
    color: rgb(13, 154, 255);
    letter-spacing: -0.05rem !important;
}

.spacer {
    height:10px;
    margin-left:-20px;
    margin-right:-20px;
    margin-bottom:20px;
    background-color: rgb(244, 244, 244);
}

.top-navi {
    position: fixed;
    padding: 20px !important;
    margin: 0 0 0 0 !important;
    height: 70px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #fff;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateY(0);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    flex: 1;
}

a {
    text-decoration: none;
}
.card {
    border-radius: 1.5em !important;
    box-shadow: 5px 5px 40px rgba(0,0,0,.12);
    overflow: hidden;

}

</style>