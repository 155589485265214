<template>
  <v-app>
    <v-main>
      <!-- <v-app-bar
        height="70px"
        color="white"
        prominent
        hide-on-scroll
        elevation="0"
      > 
        <svg version="1.1" id="&#xB808;&#xC774;&#xC5B4;_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          width="80px" height="auto" viewBox="0 0 104 50" xml:space="preserve">
        <g>
          <path style="fill:#0D9AFF;" d="M60.611,3.752h-7.022l-5.86,6.161l2.913,2.477l5.18-5.356h0.34v26.791h-7.833v3.758h20.024v-3.758
            h-7.742V3.752z"/>
          <path style="fill:#0D9AFF;" d="M99.978,2.799h-4.177v4.16H78.384v-4.16h-4.177V17.24h25.771V2.799z M95.801,13.919H78.384v-3.644
            h17.417V13.919z"/>
          <path style="fill:#0D9AFF;" d="M101.514,38.042v-3.401H78.22v-7.198h-4.257v7.963c0,0.85,0.227,1.508,0.691,1.967
            c0.465,0.448,1.145,0.674,2.052,0.674h24.813L101.514,38.042z"/>
          <path style="fill:#0D9AFF;" d="M85.395,30.797h4.308v-7.034H105v-3.48H68.959v3.48h16.436V30.797z"/>
        </g>
        <g>
          <path style="fill:#0D9AFF;" d="M22.91,0.366l-1.161,11.101c4.818,0.504,8.574,4.577,8.574,9.529c0,5.292-4.29,9.583-9.583,9.583
            c-5.292,0-9.583-4.29-9.583-9.583c0-5.292,4.29-9.583,9.583-9.583V0.251c-11.457,0-20.745,9.288-20.745,20.745
            c0,11.457,9.288,20.745,20.745,20.745c11.457,0,20.745-9.288,20.745-20.745C41.485,10.272,33.347,1.451,22.91,0.366z"/>
        </g>
        </svg>
        <v-toolbar-title> 자산관리</v-toolbar-title>
      </v-app-bar> -->
      <!-- <v-sheet
        id="scrolling-techniques-4"
        class="overflow-y-auto"
        max-height="1000"
      >
      
      </v-sheet> -->
      
      <router-view class="pa-5 mt-5 mb-5"/>
      
      <vtoast ref="vtoast"/>

    </v-main>
  </v-app>
</template>

<script>

import vtoast from './components/vtoast.vue'
export default {
  name: 'App',
  components:{
    vtoast,
  },
  data: () => ({
    //
  }),
  mounted() {
    this.$root.vtoast = this.$refs.vtoast;
  }
};
</script>

<style>  

@font-face {
    font-family: 'IBMPlexSansKR-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NanumSquareNeo-Variable';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

html, body, div, p, span{
  font-family: "NanumSquareNeo-Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
  letter-spacing: -0.02rem !important;
  font-size:14px !important;
}

.v-application, h1, h2, h3, h4 {
  font-family: "NanumSquareNeo-Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
  letter-spacing: -0.03rem !important;  
}

.v-application .text-subtitle-1 {
  font-family: "NanumSquareNeo-Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif !important;
  letter-spacing: -0.03rem !important;
}

.container, h1, h2, h3, h4 {  
  max-width: 700px !important;  
}

.kakaoLoginButton {
  background-color: #fee501 !important;
  color: #000000 !important;
  letter-spacing: -0.5px !important;
}

.bottomFixedDiv {
  position: fixed;
  left:0px;
  bottom: 20px;
  width:100%;
}

.bottomButton {  
  max-width:700px !important;
  min-height: 50px !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  color: #FFFFFF !important;
  background-color: #7030A0 !important;
}

</style>