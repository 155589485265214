<template>

    <v-container fill-height fluid>
        
        <div class="loading">
        <v-progress-circular
            :size="70"
            :width="10"
            color="green"
            indeterminate
            class="mb-5"
            ></v-progress-circular>
            <p>데이터를 수집하고 있습니다.</p>
        <!-- <v-row>
            <v-col>
                <v-btn dark large block v-on:click="loading">결과 보기</v-btn>
            </v-col>
        </v-row> -->
        </div>
    </v-container>

</template>

<script>
// import CertRequest from './components/CertRequest';
import axios from 'axios';
export default {
    name: 'CalcResult',

    components: {
        // CertRequest,
    },

    data: () => ({
        //
    }),
    mounted: function () {
        this.$nextTick(function () {
            // Code that will run only after the
            // console.log(this.$store.state.userInfo);
            this.loading();
        })
    }, 
    methods : {
        loading : async function() {

            const payload = {
                userInfo : this.$store.state.userInfo,
                // response_data : this.$store.state.certResponseData,
                cookies : this.$store.state.cookies,
            };
            console.log(payload);

            try {
                let config = {
                    headers: {
                        // Authorization: "Bearer " + this.$store.state.token,
                        Authorization: "Bearer " + localStorage.getItem('token'),
                    }
                }

                // const resp = await axios.post("https://dv-api.simplesol.kr/loading",payload);
                const resp = await axios.post(process.env.VUE_APP_URL+ "/get/asset",payload, config);

                // if (resp.data.result == "succeed") {
                    // console.log(resp.data.response);
                    console.log(resp.data);
                    this.$store.state.assets = resp.data;
                    this.$router.replace({path:'/'});
                // } else {
                //     this.$router.replace({path:'/error'});
                // }
            } catch {
                this.$router.replace({path:'/error'});
            }
            

        }

    },
      
};
</script>

<style>

</style>