<template>
    <v-app>
        <v-main>
            <v-container>
                
                <div class="loading">                    
                    <svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 0 25 25" width="100px" fill="#cc253b"><path d="M0 0h24v24H0z" fill="none"/><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>
                <p>에러가 발생하였습니다.<br>고객센터에 문의해주시기 바랍니다.</p>
                
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
// import CertRequest from './components/CertRequest';
// import axios from 'axios';
export default {
    name: 'CalcResult',
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    components: {
        // CertRequest,
    },

    data: () => ({
        //
    }),
    mounted: function () {
        this.$nextTick(function () {

        })
    }, 
    methods : {


    },
      
};
</script>

<style>
.loading{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
</style>