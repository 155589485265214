<template>



        <v-container>
            
            <!-- <h1>자산</h1>
            <v-spacer class="ma-10"></v-spacer> -->
            <!-- <p class="text-subtitle-1 mb-0">예상 총가치</p> -->
            <!-- <div class="fixed-bar" v-show="show">
                <div class="mb-10 ml-3">
                    <v-row align="center">
                        <svg class='mr-3' width="75" height="30" viewBox="0 0 75 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" fill="#0D9AFF"><path d="m15.536 8.19.829-7.929c7.455.775 13.268 7.076 13.268 14.736 0 8.184-6.634 14.818-14.818 14.818S-.003 23.181-.003 14.997C-.003 6.814 6.631.18 14.815.18v7.973a6.845 6.845 0 1 0 .72.039Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M71.414 2H68.43v2.97H55.99V2h-2.984v10.314h18.408V2ZM68.43 9.941H55.99V7.339h12.44v2.603Z"></path><path d="M43.295 2.68h-5.016l-4.186 4.4 2.08 1.77 3.7-3.826h.244v19.137h-5.595v2.684h14.303V24.16h-5.53V2.68ZM72.511 24.743v2.43l.004.003H54.79c-.648 0-1.133-.161-1.466-.481-.331-.328-.493-.798-.493-1.405v-5.688h3.04v5.141h16.639Z"></path><path d="M60.998 21.998h3.077v-5.025H75v-2.485H49.257v2.485h11.74v5.025Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h75v30H0z"></path></clipPath></defs></svg>                    
                    </v-row>
                </div>
            </div> -->
            
            
            <div class="top-navi">
                <div class="logo">
                    <svg width="75" height="30" viewBox="0 0 75 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)" fill="#0D9AFF"><path d="m15.536 8.19.829-7.929c7.455.775 13.268 7.076 13.268 14.736 0 8.184-6.634 14.818-14.818 14.818S-.003 23.181-.003 14.997C-.003 6.814 6.631.18 14.815.18v7.973a6.845 6.845 0 1 0 .72.039Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M71.414 2H68.43v2.97H55.99V2h-2.984v10.314h18.408V2ZM68.43 9.941H55.99V7.339h12.44v2.603Z"></path><path d="M43.295 2.68h-5.016l-4.186 4.4 2.08 1.77 3.7-3.826h.244v19.137h-5.595v2.684h14.303V24.16h-5.53V2.68ZM72.511 24.743v2.43l.004.003H54.79c-.648 0-1.133-.161-1.466-.481-.331-.328-.493-.798-.493-1.405v-5.688h3.04v5.141h16.639Z"></path><path d="M60.998 21.998h3.077v-5.025H75v-2.485H49.257v2.485h11.74v5.025Z"></path></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h75v30H0z"></path></clipPath></defs></svg>                
                </div>
                <div class="icon">
                    <a href="#" onclick="ChannelIO('showMessenger');" style="font-size:16px;color: rgb(13, 154, 255);">AI세무상담</a>
                </div>
            </div>



            <h2 class="mt-10 pt-5 mb-3">평균연봉 순위 (TOP30)</h2>
            
            <p class="mb-10"><small>2022년 연말정산 소득 기준 (2023.5.7. 현재)</small></p>

            <div class="spacer mb-5"></div>
            
            <template v-if="data != {}">
                <v-card
                class="ma-0 mb-3 card"
                outlined                                    
                v-for="(item,index) in data" :key="item.id"
                >

                    <v-card-text class="pt-0 pb-0">                            
                        <v-row>
                            <v-col>
                                <v-list-item two-line class="ma-0 pa-2">
                                    <v-list-item-content>
                                        <v-list-item-title v-if="item._id.사업자번호=='1048137225'">{{ index + 1 }}. SK텔레콤(주)</v-list-item-title>
                                        <v-list-item-title v-else>{{ index+1}}. {{item._id.상호.replace(/ /g,"")}}</v-list-item-title>
                                        <v-list-item-subtitle><small>{{ item._id.사업자번호 }}</small></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col>
                                <v-list-item two-line class="ma-0 pa-2">
                                    <v-list-item-content>
                                        <!-- 오른쪽 글자 정렬 -->
                                        <v-list-item-title class="text-right number"><b>{{numeral(item.평균급여).format("0,0")}}</b>원</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <!-- <h2 class="mt-10 mb-5 ml-3">예상세액</h2>            
            <v-card
            class="ma-0 mb-5 card"
            outlined                                    
            >

                <v-card-text class="pt-0 pb-0">                            
                    <v-row>
                        <v-col>
                            <v-list-item two-line class="ma-0 pa-2">
                                <v-list-item-content>
                                    <v-list-item-title>연말정산</v-list-item-title>
                                    <v-list-item-subtitle>예상납부(환급)금</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col>
                            <v-list-item two-line class="ma-0 pa-2">
                                <v-list-item-content>
                                    
                                    <v-list-item-title class="text-right"><b>100,000</b>원</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> -->
            <!-- <v-card
            class="ma-0 mb-5 card"
            outlined                                    
            >

                <v-card-text class="pt-0 pb-0">                            
                    <v-row>
                        <v-col>
                            <v-list-item two-line class="ma-0 pa-2">
                                <v-list-item-content>
                                    <v-list-item-title>종합소득세</v-list-item-title>
                                    <v-list-item-subtitle>예상납부(환급)액</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col>
                            <v-list-item two-line class="ma-0 pa-2">
                                <v-list-item-content>

                                    <v-list-item-title class="text-right"><b>300,000</b>원</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card> -->
            <!-- <v-card
            class="ma-0 mb-5 card"
            outlined                                    
            >

                <v-card-text class="pt-0 pb-0">                            
                    <v-row>
                        <v-col>
                            <v-list-item two-line class="ma-0 pa-2">
                                <v-list-item-content>
                                    <v-list-item-title>재산세/종부세</v-list-item-title>                                    
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col>
                            <v-list-item two-line class="ma-0 pa-2">
                                <v-list-item-content>
                                    <v-list-item-title class="text-right"><b>800,000</b>원</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>      
            
            <h2 class="mt-10 mb-5 ml-3">납부세액</h2>            

            <GChart
                type="ColumnChart"
                :data="chartData"
                :options="chartOptions"
            /> -->

            <!-- <v-bottom-navigation class="bottom_navigation" :elevation="0" grow>
                <v-btn value="recent">
                    홈
                </v-btn>

                <v-btn value="favorites">
                    부동산
                </v-btn>

                <v-btn value="nearby">
                    투자
                </v-btn>
                <v-btn value="nearby">
                    더보기
                </v-btn>
            </v-bottom-navigation> -->

        </v-container>

</template>

<script>

import axios from 'axios';
import numeral from 'numeral';

// import { Bar } from 'vue-chartjs'
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
// import { GChart } from 'vue-google-charts'


export default {
    name: 'CalcResult',

    components: {
        // CertRequest,
        // numeral,
        // Bar
        // GChart
    },
    data: () => ({
        show: false, // 바가 보이도록 제어하는 데이터
        dialog : false,
        loading: false,
        page_loading : false,
        data : [],
        config : {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            }
        },

    }),

    methods : {
        numeral,
        getData() {
            this.page_loading = true;
            axios.get(process.env.VUE_APP_URL + '/query/salary', this.config).then(response => {
                this.data = response.data;
                this.page_loading = false;
            }).catch(e => {
                console.log(e);
                this.page_loading = false;
            });

        }

    },
    mounted: function () {
        
        this.getData();
        
    },

};
</script>

<style scoped>
.total_amt {
    font-size: 25px !important;
    font-weight: bold;    
    line-height: 1.3;
    color: rgb(13, 154, 255);
    letter-spacing: -0.05rem !important;
}

.spacer {
    height:10px;
    margin-left:-20px;
    margin-right:-20px;
    margin-bottom:20px;
    background-color: rgb(244, 244, 244);
}

.top-navi {
    position: fixed;
    padding: 20px !important;
    margin: 0 0 0 0 !important;
    height: 70px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: #fff;
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateY(0);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    flex: 1;
}

a {
    text-decoration: none;
}

.card {    
    border-radius: 1.25em;
    box-shadow: 3px 3px 20px rgba(0,0,0,.12);
    overflow: hidden;
}
.bottom_navigation {
    position:absolute;
    bottom:0px;
    left:0px;
    right:0px;
    z-index: 999;
    height: 100px;
}

@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');
.number {
    font-family: 'NanumSquare' !important;
}
</style>