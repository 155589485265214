<template>

  <v-container>
    <v-row>
      
      <v-col class="">
          <h2 class="mb-5">
          보유자산 조회
          </h2>
          <p>카카오인증 후 아래 버튼을 눌러주세요.</p>
      </v-col>

    </v-row>
    <div class="bottomFixedDiv pr-5 pl-5">
      <v-btn 
        large
        block
        color="primary"
        elevation="0"
        class="bottomButton"
        @click="requestConfirm()"
      >
          간편인증 완료
      </v-btn>
    </div>


  </v-container>

</template>

<script>

  import axios from 'axios';

  export default {
    name: 'CertConfirm',
    data: () => ({
        snackbar : false,
        snackbarText : "",
    }),
    methods : {
      requestConfirm : async function() {

        const payload = {
            userInfo : this.$store.state.userInfo,
            response_data : this.$store.state.certResponseData,
        };
        console.log(payload);

        let config = {
          headers: {
            // Authorization: "Bearer " + this.$store.state.token,
            Authorization: "Bearer " + localStorage.getItem('token'),
          }
        }

        // try {
        const resp = await axios.post(process.env.VUE_APP_URL+ "/cert/confirm",payload, config);
        
        console.log(resp.data);

        if (resp.data.data.clientMessage != "성공") {
            // console.log(resp.data);
            this.snackbar = true;
            this.snackbarText = "인증에 실패하였습니다. 인증 후 눌러주세요.";
        } else {

            // 쿠키 정보를 state에 저장
            this.$store.state.cookies = resp.data.cookies;
            
            // 수집중 화면으로 이동
            this.$router.push({path:'/loading'});
                            
        }

        // } catch {
        //     this.snackbar = true;
        //     this.snackbarText = "오류가 발생하였습니다.";
        // }
        
        

      },
      
    },
    watch: {
      
    }
  }
</script>
